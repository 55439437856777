var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('StandardCardHeader',{attrs:{"go-to-link":'admin-stock-management',"title":'Add material to stock',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}}),_c('v-form',{ref:"form",staticClass:"ma-0 pt-6",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('Name')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.name),callback:function ($$v) {_vm.$set(_vm.stockItem, "name", $$v)},expression:"stockItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('Code')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.code),callback:function ($$v) {_vm.$set(_vm.stockItem, "code", $$v)},expression:"stockItem.code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('v-autocomplete',{staticClass:"w-full",attrs:{"items":_vm.$store.getters.getStockItemCategories,"dense":"","outlined":"","label":_vm.$t('Category'),"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.stock_item_category_id),callback:function ($$v) {_vm.$set(_vm.stockItem, "stock_item_category_id", $$v)},expression:"stockItem.stock_item_category_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-text-field',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"type":"text","dense":"","outlined":"","label":_vm.$t('Price')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.price),callback:function ($$v) {_vm.$set(_vm.stockItem, "price", $$v)},expression:"stockItem.price"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-autocomplete',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"items":['kom', 'cm2'],"dense":"","outlined":"","label":_vm.$t('Unit')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.unit),callback:function ($$v) {_vm.$set(_vm.stockItem, "unit", $$v)},expression:"stockItem.unit"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-text-field',{directives:[{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('Quantity'),"type":"text"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.stockItem.quantity),callback:function ($$v) {_vm.$set(_vm.stockItem, "quantity", $$v)},expression:"stockItem.quantity"}})],1)],1)],1)],1),_c('StandardCardHeader',{attrs:{"go-to-link":'admin-stock-management',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close' ))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }